<template>
  <div class="d-flex flex-column content landing">
    <div class="flex-grow-1 d-flex flex-md-row flex-column">
      <div class="h-100 w-100 position-md-relative">
        <div class="become-creator">
          <div class="become-creator__header">
            <button class="become-creator__close" @click="$router.go(-1)">
              <CIcon
                name="close-creator"
                class="become-creator__icon become-creator__icon--close"
              />
            </button>
          </div>

          <div class="become-creator__title">
            <span class="become-creator__font become-creator__font--title">
              {{ $t("general.becomePartner.titleVerify") }}&nbsp;<CIcon
                name="verify"
                class="become-creator__icon become-creator__icon--verify"
              />
            </span>
          </div>

          <div class="creator-avatar">
            <span class="creator-avatar__image-wrapper">
              <img src="/img/partner.webp" class="creator-avatar__image" />
            </span>

            <span class="creator-avatar__icon-wrapper">
              <CIcon name="fanflow" class="creator-avatar__icon" />
            </span>
          </div>

          <div class="become-creator__text">
            {{ $t("general.becomePartner.topText.textVerify.start") }}<br />
            {{ $t("general.becomePartner.topText.textVerify.end") }}
          </div>

          <div class="become-creator__content">
            <div class="become-creator__verification">
              <div
                v-for="item in verificationList"
                :key="item.id"
                class="button become-creator-verification"
                :class="{
                  'become-creator-verification--active': item.isActive,
                }"
              >
                <CIcon
                  :name="item.isActive ? 'check' : 'false'"
                  class="become-creator-verification__icon become-creator-verification__icon--check"
                />

                <span class="become-creator-verification__font">
                  {{ item.name }}
                </span>
              </div>
            </div>

            <div class="become-creator__button">
              <CButton
                variant="primary"
                class="w-100"
                @click.stop="startVerify"
                :disabled="!isAllVerified"
              >
                {{ $t("general.becomePartner.button.submit") }}
              </CButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@/features/ui/СButton.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import userLocation from "@/mixins/userLocation";

import CIcon from "@/features/ui/CIcon.vue";
import { mapActions, mapGetters } from "vuex";
import { partnerStatus } from "@/config/partnerStatus";

export default {
  name: "PageBecomePartnerVerify",
  components: {
    CIcon,
    CButton,
  },
  mixins: [validationError, waitRequest, userLocation],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ user: "user" }),
    verificationList() {
      return [
        {
          id: 1,
          name: this.$t("general.becomePartner.verificationList.email"),
          isActive: this.user.is_email_verified,
        },
        {
          id: 3,
          name: `${this.$t("general.becomePartner.verificationList.identity")} ${this.verificationStatusText}`,
          isActive: [partnerStatus.approved].includes(
            this.partnerStatus?.status
          ),
        },
      ];
    },
    verificationStatusText() {
      const status = {
        [partnerStatus.pending]: "Pending",
        [partnerStatus.decline]: "Canceled",
      };
      return (
        status?.[this.partnerStatus?.status] || ''
      );
    },

    isAllVerified() {
      return this.user.is_email_verified && [partnerStatus.approved].includes(
        this.partnerStatus?.status
      );
    },
  },
  methods: {
    ...mapActions({
      fetchStartVerification: "verification/fetchStartVerification",
    }),

    initState() {
      // this.user.is_email_verified
    },

    startVerify() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.fetchStartVerification()
          .then((response) => {
            window.location.href = response.data.url;
          })
          .catch(this.checkErrors);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.font {
  &--policy {
    color: $app-font-color-gray-2;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.icon {
  &--check {
    width: em(14);
    height: em(10);
  }

  &--false {
    width: em(15);
    height: em(15);
  }
}

@import "~@/assets/scss/vendors/_variables.scss";
.promo {
  background: $primary;
  color: $white;
  .middle {
    width: 100%;
    max-width: 344px;
    .logo {
      height: 4rem;
    }
    h2 {
      font-weight: normal;
    }
  }
}

.main {
  width: 344px;

  .logo {
    width: 80%;
    max-height: 4rem;
    margin: 0 auto;
  }
}

.become-creator {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    margin-top: em(20);
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    width: auto;
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);
    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: em(20);
    padding-right: em(20);
  }

  &__verification {
    padding-left: em(25);
    padding-right: em(25);
  }

  &__text {
    margin-top: em(28);
    text-align: center;
  }

  &__verification {
    margin-top: em(23);
  }

  &__benefits {
    padding-left: em(0);
    padding-right: em(25);
  }

  &__button {
    margin-top: em(32);
  }

  &__icon {
    &--verify {
      width: em(24, 24);
      height: em(24, 24);
    }

    &--close {
      width: em(22, 16);
      height: em(22, 16);
    }
  }

  &__font {
    color: $black;
    font-family: $font-default;
    font-style: normal;

    &--text {
      font-size: em(14);
      font-weight: 400;
    }

    &--title {
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

@include media-breakpoint-down(sm) {
  .w-50 {
    width: 100% !important;
  }
  .main {
    width: 90%;
  }
}

.become-creator-verification {
  $parent: &;

  &:not(:last-child) {
    margin-bottom: em(11);
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background-color $time-normal $ease, color $time-normal $ease;

  &:before {
    content: "";
    display: inline-block;
    width: em(16);
    min-width: em(16);
    height: em(16);
    min-height: em(16);
    //background-color: transparent;
    //border: 1px solid $black;
    border-radius: 50%;
    margin-right: em(8);
    transition: background-color $time-normal $ease, border $time-normal $ease;
  }

  &__font {
    color: $app-font-color-gray-3;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
  }

  &__icon {
    display: inline-block;
    width: em(14);
    min-width: em(14);
    height: em(14);
    margin-right: em(4);

    position: absolute;
  }

  &--active {
    //&:before {
    //  background-color: $app-blue;
    //  border: 1px solid $app-blue;
    //}
  }
}

.creator-avatar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $app-font-color-gray-1;
  margin-top: em(14);

  &__image-wrapper {
    position: relative;
    border-radius: 50%;
    border: 4px solid #ededed;
    z-index: 3;
  }

  &__image {
    width: em(123);
  }

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(43);
    height: em(57);

    position: absolute;
    bottom: em(-18);
    z-index: 3;
    color: $app-blue-3;
  }

  &__icon {
    width: em(43);
    height: em(57);
  }
}
</style>
